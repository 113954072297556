import React from "react"
import Seo from "../components/Seo"

export default function About() {
  return (
    <>
      <Seo title="About" />
      <h1>About</h1>
      <hr />
      <p>This page is a placeholder.</p>
    </>
  )
}
